import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextLoop from "react-text-loop";

const NavBrand = ({ siteTitle, siteUrl }) => {

  return (
    <div className="brand">
      <Typography className="site-title">
        <a href={ siteUrl }>
          <span>{ siteTitle }</span>
        </a>
      </Typography>
    </div>
  );
}

export default NavBrand;
